.accordion-margin .accordion-item.custom-accordionItem {
  box-shadow: none !important;
}

#user-profile .profile-header.custom-profile-header {
  overflow: inherit;
}

@media only screen and (max-width: 767px) {
  .datepicker-sp {
    width: 100% !important;
  }
  .custom-dashboar-nav-sp {
    margin-bottom: 0 !important;
    .nav-link {
      padding: 0.5rem 1rem !important;
      margin-bottom: 1rem;
    }
  }
  .staff-role-sp {
    margin-bottom: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .custom-statshorizontal {
    &-sp {
      flex-wrap: wrap;
      justify-content: center !important;
    }
    &-content {
      width: 100%;
      margin-bottom: 10px;
      h2 {
        text-align: center;
      }
      p {
        line-height: 1.8;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .custom-statshorizontal {
    &-sp {
      flex-wrap: wrap;
      justify-content: center !important;
    }
    &-content {
      width: 100%;
      margin-bottom: 10px;
      h2 {
        text-align: center;
      }
      p {
        line-height: 1.8;
      }
    }
  }
}

.custom-loading {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  .b {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .b div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #e6641f;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .b div:nth-child(1) {
    left: 8px;
    animation: loading_b1 0.6s infinite;
  }

  .b div:nth-child(2) {
    left: 8px;
    animation: loading_b2 0.6s infinite;
  }

  .b div:nth-child(3) {
    left: 32px;
    animation: loading_b2 0.6s infinite;
  }

  .b div:nth-child(4) {
    left: 56px;
    animation: loading_b3 0.6s infinite;
  }
}

.overlay-loading-datatable {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(239, 242, 245, 0.4);
  opacity: 0;
  transition: all 0.5s;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  .b {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .b div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #e6641f;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .b div:nth-child(1) {
    left: 8px;
    animation: b1 0.6s infinite;
  }
  .b div:nth-child(2) {
    left: 8px;
    animation: b2 0.6s infinite;
  }
  .b div:nth-child(3) {
    left: 32px;
    animation: b2 0.6s infinite;
  }
  .b div:nth-child(4) {
    left: 56px;
    animation: b3 0.6s infinite;
  }
  @keyframes b1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes b3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes b2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}
.overlay-loading-datatable.show {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
