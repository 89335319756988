.form-control.form-date-picker {
  &:disabled,
  &[readonly] {
    background-color: #fff;
  }
}
svg {
  outline: none !important;
}

.no-shadow {
  box-shadow: unset !important;
}

// *** IMPORT TABLE ***
.table-import {
  td.input-cell {
    padding: 0;
    input[type='text'] {
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 15px 25px;
      cursor: text;
    }
  }
}
